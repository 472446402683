import React from "react"
import { StaticQuery, graphql } from "gatsby"

const socialIconsQuery = graphql`
  query {
    allPrismicSocialIcons {
      nodes {
        data {
          apple_music_icon {
            url
            alt
          }
          bandcamp_icon {
            url
            alt
          }
          facebook_icon {
            url
            alt
          }
          instagram_icon {
            url
            alt
          }
          spotify_icon {
            url
            alt
          }
          twitter_icon {
            url
            alt
          }
        }
      }
    }
  }
`

const renderSocialIcons = data => {
  const socialIconsDoc = data.allPrismicSocialIcons.nodes[0].data

  if (!socialIconsDoc) {
    return null
  }
  return (
    <>
      <a
        href="https://twitter.com/let_spin"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={socialIconsDoc.twitter_icon.url}
          alt={socialIconsDoc.twitter_icon.alt}
        />
      </a>
      <a
        href="https://www.facebook.com/LetSpin/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={socialIconsDoc.facebook_icon.url}
          alt={socialIconsDoc.facebook_icon.alt}
        />
      </a>
      <a
        href="https://www.instagram.com/letspin/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={socialIconsDoc.instagram_icon.url}
          alt={socialIconsDoc.instagram_icon.alt}
        />
      </a>
      <a
        href="https://letspin.bandcamp.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={socialIconsDoc.bandcamp_icon.url}
          alt={socialIconsDoc.bandcamp_icon.alt}
        />
      </a>
      <a
        href="https://open.spotify.com/artist/7uZIbR8sU92yLezE1MnQ4g"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={socialIconsDoc.spotify_icon.url}
          alt={socialIconsDoc.spotify_icon.alt}
        />
      </a>
      <a
        href="https://music.apple.com/gb/artist/let-spin/800391899"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={socialIconsDoc.apple_music_icon.url}
          alt={socialIconsDoc.apple_music_icon.alt}
        />
      </a>
    </>
  )
}

const SocialIcons = () => (
  <>
    <StaticQuery query={socialIconsQuery} render={renderSocialIcons} />
  </>
)

export default SocialIcons

import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import "./style.scss"
import colors from "../../utils/colors"

const footerQuery = graphql`
  query {
    allPrismicFooter {
      nodes {
        data {
          efpi_logo {
            url
            alt
          }
          efpi_logo_link {
            url
          }
          gdpr_link_text
        }
      }
    }
  }
`

const renderFooter = data => {
  const footerDoc = data.allPrismicFooter.nodes[0].data

  if (!footerDoc) {
    return null
  }
  return (
    <footer>
      <div className="footer-border-div" />
      <a
        href={footerDoc.efpi_logo_link.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={footerDoc.efpi_logo.url} alt={footerDoc.efpi_logo.alt} />
      </a>
      <p>
        © {new Date().getFullYear()} Let Spin | Made by{" "}
        <a
          href="https://sarahhouben.com/index.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          SH
        </a>
      </p>
      <Link
        className="terms-link"
        to="/terms"
        activeStyle={{ color: colors.mandyRed }}
      >
        {footerDoc.gdpr_link_text}
      </Link>
    </footer>
  )
}

const Footer = () => (
  <>
    <StaticQuery query={footerQuery} render={renderFooter} />
  </>
)

export default Footer

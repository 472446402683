import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import SocialIcons from "../socialIcons/index"
import ToggleButton from "../sidebar/toggleButton/index"
import colors from "../../utils/colors"

import "./style.scss"

const Toolbar = props => {
  const {
    allPrismicAbout,
    allPrismicMusic,
    allPrismicContact,
    allPrismicTour,
    allPrismicYoutubeVideo,
    allPrismicHeader,
  } = props.headerData

  const headerDoc = allPrismicHeader.nodes[0].data
  const aboutVisible = allPrismicAbout.nodes[0].data
  const musicVisible = allPrismicMusic.nodes[0].data
  const tourVisible = allPrismicTour.nodes[0].data
  const videoVisible = allPrismicYoutubeVideo.nodes[0].data
  const contactVisible = allPrismicContact.nodes[0].data

  return (
    <div className="toolbar-div">
      <header className="toolbar">
        <nav className="toolbar-nav">
          <div className="togglebutton-div">
            <ToggleButton onClick={props.drawerClickHandler} />
          </div>
          <div className="toolbar-logo">
            <AnchorLink to="/#home" title="Home" stripHash>
              <img
                src={headerDoc.let_spin_logo.url}
                alt={headerDoc.let_spin_logo.alt}
              />
            </AnchorLink>
          </div>
          <div className="spacer" />
          <div className="sidebar-logo">
            <AnchorLink to="/#home" title="Home" stripHash>
              <img
                src={headerDoc.let_spin_logo.url}
                alt={headerDoc.let_spin_logo.alt}
              />
            </AnchorLink>
          </div>
          <div className="toolbar-link-div">
            <div className="toolbar-nav-items">
              <ul>
                {aboutVisible && (
                  <li>
                    <AnchorLink
                      to="/#about"
                      title="About"
                      stripHash
                      activeStyle={{ color: colors.mandyRed }}
                    >
                      <h2>{headerDoc.about_link_text}</h2>
                    </AnchorLink>
                  </li>
                )}
                {musicVisible && (
                  <li>
                    <AnchorLink
                      to="/#music"
                      title="Music"
                      stripHash
                      activeStyle={{ color: colors.mandyRed }}
                    >
                      <h2>{headerDoc.music_link_text}</h2>
                    </AnchorLink>
                  </li>
                )}
                {tourVisible && (
                  <li>
                    <AnchorLink
                      to="/#tour"
                      title="Tour"
                      stripHash
                      activeStyle={{ color: colors.mandyRed }}
                    >
                      <h2>{headerDoc.tour_link_text}</h2>
                    </AnchorLink>
                  </li>
                )}
                {videoVisible && (
                  <li>
                    <AnchorLink
                      to="/#video"
                      title="Video"
                      stripHash
                      activeStyle={{ color: colors.mandyRed }}
                    >
                      <h2>{headerDoc.video_link_text}</h2>
                    </AnchorLink>
                  </li>
                )}
                {contactVisible && (
                  <li>
                    <AnchorLink
                      to="/#contact"
                      title="Contact"
                      stripHash
                      activeStyle={{ color: colors.mandyRed }}
                    >
                      <h2>{headerDoc.contact_link_text}</h2>
                    </AnchorLink>
                  </li>
                )}
              </ul>
            </div>
            <div className="icon-sidebar-div ">
              <SocialIcons />
            </div>
          </div>
        </nav>
      </header>
      <div className="header-border-div" />
    </div>
  )
}

export default Toolbar

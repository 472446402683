import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import SocialIcons from "../../socialIcons/index"

import "./style.scss"

const Sidedrawer = props => {
  let drawerClasses = "sidedrawer"
  if (props.show === true) {
    drawerClasses = "sidedrawer open"
  }

  const {
    allPrismicAbout,
    allPrismicMusic,
    allPrismicContact,
    allPrismicTour,
    allPrismicYoutubeVideo,
    allPrismicHeader,
  } = props.headerData

  const headerDoc = allPrismicHeader.nodes[0].data
  const aboutVisible = allPrismicAbout.nodes[0].data
  const musicVisible = allPrismicMusic.nodes[0].data
  const tourVisible = allPrismicTour.nodes[0].data
  const videoVisible = allPrismicYoutubeVideo.nodes[0].data
  const contactVisible = allPrismicContact.nodes[0].data

  return (
    <nav className={drawerClasses}>
      <ul>
        {aboutVisible && (
          <li>
            <AnchorLink to="/#about" title="About" stripHash>
              <h2 onClick={props.onClick}>{headerDoc.about_link_text}</h2>
            </AnchorLink>
          </li>
        )}
        {musicVisible && (
          <li>
            <AnchorLink to="/#music" title="Music" stripHash>
              <h2 onClick={props.onClick}>{headerDoc.music_link_text}</h2>
            </AnchorLink>
          </li>
        )}
        {tourVisible && (
          <li>
            <AnchorLink to="/#tour" title="Tour" stripHash>
              <h2 onClick={props.onClick}>{headerDoc.tour_link_text}</h2>
            </AnchorLink>
          </li>
        )}
        {videoVisible && (
          <li>
            <AnchorLink to="/#video" title="Video" stripHash>
              <h2 onClick={props.onClick}>{headerDoc.video_link_text}</h2>
            </AnchorLink>
          </li>
        )}
        {contactVisible && (
          <li>
            <AnchorLink to="/#contact" title="Contact" stripHash>
              <h2 onClick={props.onClick}>{headerDoc.contact_link_text}</h2>
            </AnchorLink>
          </li>
        )}
      </ul>
      <div className="icon-div">
        <SocialIcons />
      </div>
    </nav>
  )
}

export default Sidedrawer

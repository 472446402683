export default {
  white: "#ffffff",
  lightestBlue: "#d9dff5",
  blue: "#0000ff",
  mintGreen: "#46b093",
  khaki: "#4e503b",
  green: "#008000",
  mandyRed: "#eb4c6c",
  purple: "#3b2049",
  grey: "#6d6e70",
  blueGrey: "#363c4e",
  nearBlack: "#191e22",
  darkBlue: "#2B373B",
}

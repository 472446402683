import React from "react"
import { useState } from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import CookieConsent, { Cookies } from "react-cookie-consent"

import Backdrop from "../backdrop"
import Toolbar from "../toolbar"
import Sidedrawer from "../sidebar/sidedrawer"
import Footer from "../footer"

import colors from "../../utils/colors"
import "../../style/main.scss"

const layoutQuery = graphql`
  {
    allPrismicHeader {
      nodes {
        data {
          about_link_text
          video_link_text
          contact_link_text
          let_spin_logo {
            url
            alt
          }
          music_link_text
          tour_link_text
        }
      }
    }

    allPrismicAbout {
      nodes {
        data {
          visible
        }
      }
    }

    allPrismicMusic {
      nodes {
        data {
          visible
        }
      }
    }

    allPrismicTour {
      nodes {
        data {
          visible
        }
      }
    }

    allPrismicYoutubeVideo {
      nodes {
        data {
          visible
        }
      }
    }

    allPrismicContact {
      nodes {
        data {
          visible
        }
      }
    }
  }
`

const RenderLayout = ({ data, props }) => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false)

  let drawerClickHandler = () => setSideDrawerOpen(!sideDrawerOpen)

  let backDropClickHandler = () => {
    setSideDrawerOpen(false)
  }

  const headerData = data || null

  return (
    <>
      <Toolbar
        drawerClickHandler={drawerClickHandler}
        headerData={headerData}
      />
      <Sidedrawer
        show={sideDrawerOpen}
        onClick={() => setSideDrawerOpen(!sideDrawerOpen)}
        headerData={headerData}
      />
      {sideDrawerOpen && <Backdrop onClick={backDropClickHandler} />}

      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
          paddingTop: `100px`,
        }}
      >
        <main>{props.children}</main>
      </div>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="GOT IT"
        declineButtonText="NO THANKS"
        style={{
          background: colors.darkBlue,
          fontFamily: `"Source Sans Pro", sans-serif`,
        }}
        buttonStyle={{
          color: colors.khaki,
          fontSize: "13px",
          background: colors.white,
        }}
        onAccept={() => {
          Cookies.set("gatsby-gdpr-google-analytics", "true")
          Cookies.set("gatsby-gdpr-facebook-pixel", "true")
        }}
      >
        We use cookies to provide you with the best possible user experience.
        Click{" "}
        <Link to="/terms" style={{ textDecoration: `underline` }}>
          here
        </Link>{" "}
        for more information.
      </CookieConsent>
    </>
  )
}

const Layout = props => (
  <>
    <StaticQuery
      query={layoutQuery}
      render={data => <RenderLayout data={data} props={props} />}
    />
  </>
)

export default Layout

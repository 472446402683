import React from "react"

import "./style.scss"

const ToggleButton = props => {
  return (
    <button
      className="toggle-button"
      onClick={props.onClick}
    >
      <div className="toggle-button-line" />
      <div className="toggle-button-line" />
      <div className="toggle-button-line" />
    </button>
  )
}

export default ToggleButton

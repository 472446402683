import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function SEO({ description, lang, meta, title }) {
  const site = {
    siteMetadata: {
      title: `Let Spin`,
      description: `Let Spin are an egalitarian post-jazz four-piece whose members are active contributors to the fertile London, UK and European jazz scenes. Featuring Ruth Goller (Melt Yourself Down, Vula Viel), Chris Williams (Led Bib, Sarathy Korwar), Finlay Panter (Beats & Pieces Big Band, Sound 8 Orchestra) and Moss Freed (Union Division, Spike Orchestra).  `,
      author: `Let Spin`,
      lang: "en",
    },
  }

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script id="mcjs">
        {`
        !function(c,h,i,m,p)
        {
          ((m = c.createElement(h)),
          (p = c.getElementsByTagName(h)[0]),
          (m.async = 1),
          (m.src = i),
          p.parentNode.insertBefore(m, p))
        }
        (document,"script","https://chimpstatic.com/mcjs-connected/js/users/10627f4b4b53bac70e15220f4/79240f804817dfd9b00eccb1f.js")
       `}
      </script>
      <script
        async
        defer
        src="https://static.cdn.prismic.io/prismic.js?new=true&repo=letspin"
      />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
